<template>
    <div class="col d-sm-flex justify-content-center">
        <div class="card text-center">
            <h1>Errore nella transazione.</h1>
            <p class="text-danger">Si è verificato un errore durante la transazione</p>
            <p class="">{{ errorMsg }}</p>
            <router-link to="/" class="btn btn-primary btn-block">Torna alla home</router-link>
        </div>
    </div>
</template>

<script>

export default {
    name: "purchase-error",
    data() {
        return {
            errorMsg: ''
        }
    },
    mounted() {
        const url = location.href
        const [hash, query] = url.split('#')[1].split('?')
        const params = Object.fromEntries(new URLSearchParams(query))
        this.errorMsg = params.errorMsg
    }
};
</script>
